import { FC, ReactNode } from 'react';

import useStyles from './Heading.style';

const validHeadingLevels = ['h1', 'h2', 'h3', 'h4', 'h5'];

export type HeadingLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
type TextAlign = 'center' | 'left' | 'right';
type FontWeight = '600' | '700' | '900';

export type HeadingProps = {
	/** Heading element of the header to use */
	headingLevel: HeadingLevel;
	/** Children to render within the heading element */
	children: ReactNode;
	/** Pseudo element to put after the heading element */
	after?: ReactNode;
	/** Classname to pass through to the element */
	className?: string;
	/** ID to pass through to the element */
	id?: string;
	/** Option to align the text */
	textAlign?: TextAlign;
	/** Weight of the font to render */
	fontWeight?: FontWeight;
};

const Heading: FC<HeadingProps> = ({
	after,
	children = '',
	className,
	fontWeight,
	headingLevel = 'h1',
	id,
	textAlign,
}) => {
	const HeadingItem = validHeadingLevels.includes(headingLevel) ? headingLevel : 'p';
	const { classes: styles, cx } = useStyles();

	return (
		<HeadingItem
			className={cx(
				styles.heading,
				headingLevel && styles[`${headingLevel}`],
				textAlign && styles[`text-align-${textAlign}`],
				className,
				!!after && styles.withAfter,
				fontWeight && styles[`font-weight-${fontWeight}`],
			)}
			id={id}
		>
			<span>{children}</span>
			{after && <div className={styles.after}>{after}</div>}
		</HeadingItem>
	);
};

export default Heading;
