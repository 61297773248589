import { FC, memo, SVGProps } from 'react';

import AboutIcon from './assets/about.svg';
import Accessibility from './assets/accessibility.svg';
import AccoType from './assets/acco-type.svg';
import Accommodation from './assets/accommodation.svg';
import AccommodationsIcon from './assets/accommodations.svg';
import AdvertiseIcon from './assets/advertising.svg';
import Airco from './assets/airco.svg';
import AwardTrophy from './assets/award_trophy.svg';
import AwardsIcon from './assets/awards.svg';
import BabyCot from './assets/baby_cot.svg';
import Bed from './assets/bed.svg';
import Bolt from './assets/bolt.svg';
import Calendar from './assets/calendar.svg';
import CancelCircle from './assets/cancel-circle.svg';
import Cancel from './assets/cancel.svg';
import Chat from './assets/chat.svg';
import ChildrensPool from './assets/childrens_pool.svg';
import CirclePlus from './assets/circle-plus.svg';
import CircleX from './assets/circle-x.svg';
import ContactIcon from './assets/contact.svg';
import Cross from './assets/cross.svg';
import Destination from './assets/destination.svg';
import Less1KmToTheBeach from './assets/distance_to_anything.svg';
import EditNav from './assets/edit.svg';
import Entertainment from './assets/entertainment.svg';
import FamilyRoom from './assets/family_room.svg';
import FaqIcon from './assets/faq.svg';
import FavHeartEmpty from './assets/fav_heart_empty.svg';
import FavHeartFilled from './assets/fav_heart_filled.svg';
import Filter from './assets/filter.svg';
import Gameroom from './assets/gameroom.svg';
import Home from './assets/home.svg';
import Hotel from './assets/hotel.svg';
import DepartureIcon from './assets/icon-date-grey.svg';
import ExternalLink from './assets/icon-external.svg';
import LuggageIcon from './assets/icon-luggage-grey.svg';
import PlaneIcon from './assets/icon-plane-grey.svg';
import TransferIcon from './assets/icon-transfer-grey.svg';
import AllInclusiveIcon from './assets/icon_all-inclusive.svg';
import CarVacationIcon from './assets/icon_autovakantie.svg';
import CampingVacationIcon from './assets/icon_campingvakantie.svg';
import CityTripIcon from './assets/icon_stedentrip.svg';
import DistantTravelIcon from './assets/icon_verre-reizen.svg';
import WinterSportIcon from './assets/icon_wintersport.svg';
import SunVacationIcon from './assets/icon_zonvakantie.svg';
import CircleDownload from './assets/iconCircleDownload.svg';
import DownloadFile from './assets/iconDownloadFile.svg';
import DownloadPress from './assets/iconDownloadPress.svg';
import Info from './assets/info.svg';
import Jacuzzi from './assets/jacuzzi.svg';
import KidsClub from './assets/kids_club.svg';
import Location from './assets/location.svg';
import MagnifyGlass from './assets/magnify-glass.svg';
import OnTheBeach from './assets/on_the_beach.svg';
import Person from './assets/person.svg';
import PetsAllowed from './assets/pets_allowed.svg';
import Playground from './assets/playground.svg';
import Pool from './assets/pool.svg';
import Restaurant from './assets/restaurant.svg';
import Search from './assets/search.svg';
import Shape8Plus from './assets/shape-8-plus.svg';
import Share from './assets/share.svg';
import SportFacilities from './assets/sport_facilities.svg';
import VaknlCheck from './assets/vaknl-check.svg';
import VaknlChevronDown from './assets/vaknl-chevron-down.svg';
import VaknlChevronLeft from './assets/vaknl-chevron-left.svg';
import VaknlChevronRight from './assets/vaknl-chevron-right.svg';
import VaknlChevronUp from './assets/vaknl-chevron-up.svg';
import VaknlDeparture from './assets/vaknl-departure.svg';
import DownloadImage from './assets/vaknl-download-image.svg';
import VaknlDownload from './assets/vaknl-download.svg';
import VaknlDurationFill from './assets/vaknl-duration-fill.svg';
import VaknlExit from './assets/vaknl-exit.svg';
import VaknlHandLuggage from './assets/vaknl-hand-luggage.svg';
import VaknlHome from './assets/vaknl-home.svg';
import VaknlInboundFlight from './assets/vaknl-inbound-flight.svg';
import VaknlInfo from './assets/vaknl-info.svg';
import VaknlInsurance from './assets/vaknl-insurance.svg';
import VaknlLgAccommodation from './assets/vaknl-lg-accommodation.svg';
import VaknlLgBed from './assets/vaknl-lg-bed.svg';
import VaknlLgDeparture from './assets/vaknl-lg-departure.svg';
import VaknlLgDuration from './assets/vaknl-lg-duration.svg';
import VaknlLgInbound from './assets/vaknl-lg-inbound.svg';
import VaknlLgLocation from './assets/vaknl-lg-location.svg';
import VaknlLgLuggage from './assets/vaknl-lg-luggage.svg';
import VaknlLgMealPlan from './assets/vaknl-lg-meal-plan.svg';
import VaknlLgOutbound from './assets/vaknl-lg-outbound.svg';
import VaknlLgReturn from './assets/vaknl-lg-return.svg';
import VaknlLgTransfer from './assets/vaknl-lg-transfer.svg';
import VaknlLgTravelers from './assets/vaknl-lg-travelers.svg';
import VaknlLuggage from './assets/vaknl-luggage.svg';
import VaknlMealPlan from './assets/vaknl-meal-plan.svg';
import VaknlMinus from './assets/vaknl-minus.svg';
import VaknlOutboundFlight from './assets/vaknl-outbound-flight.svg';
import VaknlPdf from './assets/vaknl-pdf.svg';
import VaknlPlus from './assets/vaknl-plus.svg';
import VaknlPopup from './assets/vaknl-popup.svg';
import VaknlQuestion from './assets/vaknl-question.svg';
import VaknlSleekArrowLeft from './assets/vaknl-sleek-arrow-left.svg';
import VaknlSleekArrowRight from './assets/vaknl-sleek-arrow-right.svg';
import VaknlStar from './assets/vaknl-star.svg';
import VaknlTransfer from './assets/vaknl-transfer.svg';
import VaknlTravelers from './assets/vaknl-travelers.svg';
import VaknlWarning from './assets/vaknl-warning.svg';
import Waterpark from './assets/waterpark.svg';
import Wifi from './assets/wifi.svg';
import useStyles from './Icon.style';
import { IconTypes } from './Icon.types';

export const internalIcons = {
	vaknlLgAccommodation: VaknlLgAccommodation,
	vaknlLgBed: VaknlLgBed,
	vaknlLgDeparture: VaknlLgDeparture,
	vaknlLgDuration: VaknlLgDuration,
	vaknlLgInbound: VaknlLgInbound,
	vaknlLgLocation: VaknlLgLocation,
	vaknlLgLuggage: VaknlLgLuggage,
	vaknlLgMealPlan: VaknlLgMealPlan,
	vaknlLgOutbound: VaknlLgOutbound,
	vaknlLgReturn: VaknlLgReturn,
	vaknlLgTransfer: VaknlLgTransfer,
	vaknlLgTravelers: VaknlLgTravelers,
	vaknlChevronDown: VaknlChevronDown,
	vaknlChevronLeft: VaknlChevronLeft,
	vaknlChevronRight: VaknlChevronRight,
	vaknlChevronUp: VaknlChevronUp,
	vaknlExit: VaknlExit,
	vaknlCheck: VaknlCheck,
	vaknlStar: VaknlStar,
	vaknlSleekArrowLeft: VaknlSleekArrowLeft,
	vaknlSleekArrowRight: VaknlSleekArrowRight,
	vaknlLuggage: VaknlLuggage,
	vaknlHandLuggage: VaknlHandLuggage,
	vaknlMealPlan: VaknlMealPlan,
	vaknlInfo: VaknlInfo,
	vaknlHome: VaknlHome,
	vaknlWarning: VaknlWarning,
	vaknlInboundFlight: VaknlInboundFlight,
	vaknlOutboundFlight: VaknlOutboundFlight,
	vaknlTransfer: VaknlTransfer,
	vaknlDeparture: VaknlDeparture,
	vaknlPlus: VaknlPlus,
	vaknlMinus: VaknlMinus,
	vaknlTravelers: VaknlTravelers,
	vaknlDurationFill: VaknlDurationFill,
	vaknlInsurance: VaknlInsurance,
	accessibility: Accessibility,
	airco: Airco,
	allInclusiveIcon: AllInclusiveIcon,
	baby_cot: BabyCot,
	distantTravelIcon: DistantTravelIcon,
	carVacationIcon: CarVacationIcon,
	campingVacationIcon: CampingVacationIcon,
	childrens_pool: ChildrensPool,
	cityTripIcon: CityTripIcon,
	entertainment: Entertainment,
	family_room: FamilyRoom,
	gameroom: Gameroom,
	jacuzzi: Jacuzzi,
	kids_club: KidsClub,
	vaknlPdf: VaknlPdf,
	less_than_1km_to_the_beach: Less1KmToTheBeach,
	less_than_1km_to_the_center: Less1KmToTheBeach,
	on_the_beach: OnTheBeach,
	pets_allowed: PetsAllowed,
	playground: Playground,
	pool: Pool,
	restaurant: Restaurant,
	sport_facilities: SportFacilities,
	sunVacationIcon: SunVacationIcon,
	fav_heart_empty: FavHeartEmpty,
	fav_heart_filled: FavHeartFilled,
	editNav: EditNav,
	externalLink: ExternalLink,
	winterSportIcon: WinterSportIcon,
	accommodationsIcon: AccommodationsIcon,
	aboutIcon: AboutIcon,
	shape8plus: Shape8Plus,
	vaknlQuestion: VaknlQuestion,
	vaknlPopup: VaknlPopup,
	advertiseIcon: AdvertiseIcon,
	vaknlDownload: VaknlDownload,
	award_trophy: AwardTrophy,
	awardsIcon: AwardsIcon,
	contactIcon: ContactIcon,
	faqIcon: FaqIcon,
	hotel: Hotel,
	destination: Destination,
	location: Location,
	departureIcon: DepartureIcon,
	luggageIcon: LuggageIcon,
	planeIcon: PlaneIcon,
	transferIcon: TransferIcon,
	waterpark: Waterpark,
	wifi: Wifi,
	bolt: Bolt,
	search: Search,
	cross: Cross,
	downloadFile: DownloadFile,
	circleDownload: CircleDownload,
	downloadPress: DownloadPress,
	downloadImage: DownloadImage,
	accoType: AccoType,
	home: Home,
	info: Info,
	bed: Bed,
	circleX: CircleX,
	circlePlus: CirclePlus,
	share: Share,
	calendar: Calendar,
	person: Person,
	filter: Filter,
	cancel: Cancel,
	cancelCircle: CancelCircle,
	magnifyGlass: MagnifyGlass,
	accommodation: Accommodation,
	chat: Chat,
} as const;

type Props = {
	className?: string;
	type?: IconTypes;
	symbol?: FC<SVGProps<SVGSVGElement> & { title?: string }>;
	title?: string;
};

const Icon: FC<Props> = ({ className, type, symbol, title }) => {
	const Component = symbol as FC<SVGProps<SVGSVGElement> & { title?: string }>;
	const { classes, cx } = useStyles();
	const IconTypeComponent = type && internalIcons[type];

	return (
		<>
			{type ? (
				<IconTypeComponent className={cx(classes.icon, className)} />
			) : (
				<Component title={title} className={cx(classes.icon, className)} />
			)}
		</>
	);
};

const memoizedIcon = memo(Icon);
export { memoizedIcon as Icon };
