import { deprecatedMakeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

const useStyles = deprecatedMakeStyles()((theme) => ({
	heading: {
		color: theme.typography.h1.color,
		fontFamily: theme.typography.fontFamily,
		fontWeight: theme.typography.fontWeightRegular as number,
	},
	withAfter: {
		'& span': {
			display: 'inline-block',
			marginRight: theme.spacing(2),
			marginBottom: theme.spacing(1),
		},
	},
	after: {
		display: 'inline-flex',
		verticalAlign: 'middle',
	},
	h1: {
		fontFamily: theme.typography.h1.fontFamily,
		fontSize: '2.5rem',

		[theme.breakpoints.down('md')]: {
			fontSize: '1.5rem',
		},
	},
	h2: {
		fontFamily: theme.typography.h2.fontFamily,
		fontSize: '1.5rem',
	},
	h3: {
		fontFamily: theme.typography.h3.fontFamily,
		fontSize: '1.25rem',
	},
	h4: {
		fontFamily: theme.typography.h4.fontFamily,
		fontSize: '1rem',
	},
	h5: {
		fontFamily: theme.typography.h5.fontFamily,
		fontSize: '0.875rem',
	},
	'text-align-left': {
		textAlign: 'left',
	},
	'text-align-center': {
		textAlign: 'center',
	},
	'text-align-right': {
		textAlign: 'right',
	},
	'font-weight-600': {
		fontWeight: theme.typography.fontWeightMedium,
	},
	'font-weight-700': {
		// Because of that, default material ui Typography type have no prop like that
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		fontWeight: theme.typography.fontWeightMediumBold,
	},
	'font-weight-900': {
		fontWeight: theme.typography.fontWeightBold,
	},
}));

export default useStyles;
