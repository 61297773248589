import { deprecatedMakeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

const useStyles = deprecatedMakeStyles<void, 'small'>()((theme, _, classes) => ({
	card: {
		borderRadius: theme.shape.borderRadius,
		border: theme.borders?.default,
		overflow: 'hidden',
		backgroundColor: theme.palette?.common?.white,
		transition: 'all 0.2s ease-in-out',

		[`&.${classes.small}`]: {
			maxWidth: '460px',
		},
	},
	noBorder: {
		borderWidth: 0,
	},
	elevated: {
		boxShadow: `${theme.shadows[3]}, ${theme.shadows[4]}`,
		borderColor: theme.palette?.common?.white,
	},
	scroll: {
		overflow: 'scroll',
	},
	unset: {
		overflow: 'unset',
	},
	small: {},
}));

export default useStyles;
