import { deprecatedMakeStyles } from '@vakantiesnl/components/src/styles/themes/themewrapper';

const useStyles = deprecatedMakeStyles()((theme) => ({
	icon: {
		fill: 'currentColor',
		width: theme.spacing(2),
	},
}));

export default useStyles;
